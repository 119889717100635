<template>
  <content-layout :loading="loading" class="pa-0 ma-0">
    <v-app-bar app color="white" v-if="currentUser">
      <div class="d-flex full-width justify-space-between align-center">
        <div class="d-flex align-center" style="column-gap: 20px">
          <img :src="logo" :height="45" />
          <div class="font-weight-semibold text-h5" v-if="account">
            {{ account.companyName }}
          </div>
        </div>

        <cz-avatar
          :size="50"
          color="primary"
          :name="currentUser.fullName"
          initials-color="white"
          initials-size="font-weight-semibold text-h6"
        />
      </div>
    </v-app-bar>
    <router-view />
  </content-layout>
</template>

<script>
import { getInvite } from '@/core/api';
import { CzAvatar } from '@/components';
import { mapFields } from 'vuex-map-fields';
import logo from '@/assets/corz.png';
export default {
  name: 'AcceptInviteHomePage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzAvatar
  },
  created() {
    this.loadInvite();
  },
  computed: {
    ...mapFields('invite', ['invitation']),
    ...mapFields('auth', ['currentUser', 'account'])
  },
  data() {
    return {
      logo,
      loading: false
    };
  },
  methods: {
    async loadInvite() {
      try {
        this.loading = true;

        // check router query params contains a token and an email and
        // try to fetch the invite
        if (!this.$route.query.to || !this.$route.query.token) {
          this.$router.replace({
            name: 'home'
          });
          return;
        }

        this.invitation = await getInvite(
          this.$route.query.to,
          this.$route.query.token
        );
        this.routeByInvitationType(this.invitation);
      } catch (_) {
        this.$router.replace({
          name: 'home'
        });
      } finally {
        this.loading = false;
      }
    },
    routeByInvitationType(invitation) {
      if (!invitation?.invitationType) {
        return;
      }

      this.$router.replace({
        name: 'accept-invite',
        params: {
          id: invitation._id
        }
      });

      // switch (invitation.invitationType) {
      //   case InvitationType.CustomerAdmin:
      //   case InvitationType.CustomerPurchaser:
      // }
    }
  }
};
</script>

<style></style>
